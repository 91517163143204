<template>
    <div class="grid grid-cols-4 gap-4">
        <div class="md:col-span-1 col-span-4 border border-gray-400 rounded-3xl shadow-lg px-4 md:px-0">
            <div class="text-primary font-bold text-xl text-start md:text-center my-2">Destination</div>
            <div class="md:h-72vh h-64 overflow-auto md:px-4 px-0 flex">
                <div class="flex md:block md:flex-auto space-x-3 md:space-x-0">
                    <div v-for="destination in availableDestinations" :key="destination.id"
                        @click="selectDestination(destination)"
                        class="rounded-xl overflow-hidden mb-4 cursor-pointer border border-gray-200 shadow-lg hover:shadow-hover w-72 md:w-full">
                        <div class="font-bold bg-yellow py-1 px-3">{{ destination.judul }}</div>
                        <div class="px-3 pt-3">
                            <img :src="destination.link_foto"
                                class="object-cover w-full h-28 rounded-xl border border-gray-300" />
                        </div>
                        <div class="font-bold text-center text-sm py-2">{{ destination.nama_wilayah }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-4 md:col-span-3 grid grid-cols-5 gap-4 p-4 border border-gray-400 rounded-3xl shadow-lg">
            <div class="col-span-5 md:col-span-3">
                <img :src="selectedDestination.link_foto"
                    class="object-cover rounded-2xl border border-gray-300 h-48 w-full" />
                <div class="text-primary font-bold text-xl mt-4">{{ selectedDestination.judul }}</div>
                <div class="font-semibold">{{ selectedDestination.nama_wilayah }}</div>
                <div v-html="selectedDestination.isi"
                    class="h-4/6 overflow-auto border-t-2 border-gray-200 font-medium text-justify pr-2 pt-4 mt-2">
                </div>
            </div>
            <div class="col-span-5 md:col-span-2">
                <div class="border border-gray-200 rounded-2xl shadow-lg">
                    <div class="text-primary font-bold text-center my-2">Recommended Package</div>
                    <div class="h-70vh overflow-auto px-4">
                        <div v-for="item in packages" :key="item.id"
                            class="rounded-xl overflow-hidden mb-4 border border-gray-200 shadow-lg">
                            <div class="font-medium text-sm bg-primary text-white text-center py-1">
                                {{ item.nama }}
                            </div>
                            <div class="grid grid-cols-5">
                                <div class="col-span-2">
                                    <div class="p-2">
                                        <img :src="item.galery_package"
                                            class="object-cover w-full h-full rounded-xl border border-gray-300" />
                                    </div>
                                </div>
                                <div class="col-span-3 pt-2 pr-2">
                                    <div class="text-sm font-semibold">{{ item.nama_toko }}</div>
                                    <div class="flex justify-between mt-1">
                                        <div class="flex items-start">
                                            <font-awesome-icon icon="fa-regular fa-clock" size="sm" />
                                            <div class="text-xs font-medium ml-1">
                                                {{ item.durasi }} {{ item.durasi == 1 ? 'Day' : 'Days' }}
                                            </div>
                                        </div>
                                        <div class="flex items-center justify-end">
                                            <img :src="getLevelImage(item.level)" class="w-2/5" />
                                            <div class="text-xs font-medium ml-1">
                                                {{ getLevelText(item.level) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-2xs mt-1 font-medium">
                                        <div>Categories : {{ item.category }}</div>
                                    </div>
                                    <div class="text-xs mt-2 font-medium">
                                        Start from :
                                        <span class="text-sm ml-1 font-bold">
                                            {{ item.start_price | toCurrency }}
                                        </span>
                                    </div>
                                    <button type="button" @click="openSpecialOfferDetail(item)"
                                        class="bg-yellow hover:bg-yellow-darker w-full my-2 py-1 rounded-lg font-bold">
                                        BOOK NOW
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Slugify } from '@/utils';

export default {
    name: 'DestinationDetail',

    data() {
        return {
            selectedDestination: {},
            destinations: [],
            packages: []
        };
    },

    computed: {
        availableDestinations() {
            return this.destinations.filter(val => val.id != this.selectedDestination.id);
        }
    },

    beforeRouteEnter(to, from, next) {
        if (to.params.destination === undefined) {
            next({ name: 'DestinationIndex' });
        } else {
            next();
        }
    },

    async created() {
        this.$store.commit('setOverlayLoading', true);

        this.selectedDestination = this.$route.params.destination;

        this.$store.commit('setBreadcrumbItems', [
            { text: 'Home', routeName: 'Home' },
            { text: 'Destination', routeName: 'DestinationIndex' },
            { text: this.selectedDestination.judul, routeName: 'DestinationDetail' }
        ]);

        await this.getDestinations();
        await this.getPackages();

        this.$store.commit('setOverlayLoading', false);
    },

    methods: {
        openSpecialOfferDetail(pack) {
            this.$router.push({
                name: 'PackageDetail',
                params: { slug: Slugify(pack.nama), pack }
            });
        },
        setPackage(pack) {
            this.$store.commit('setPackage', pack);
            var slug = null;
            var trip = null;
            if (pack.tipe == "0") {
                slug = 'wateradventure';
            } else if (pack.tipe == "1") {
                slug = 'landadventure';
                trip = 'offroad';
            } else if (pack.tipe == "2") {
                slug = 'skyadventure';
                trip = 'onroad';
            }
            this.$router.push({ name: 'CheckoutPaket', params: { slug: slug, trip: trip } });
        },
        async getDestinations() {
            this.$store.commit('setOverlayLoading', false);
            
            try {
                this.$store.commit("setOverlayLoading", true);
                const res = await this.$http
                .get(`${this.$apiTripweWeb}/destination/list`, {
                    params: {
                        limit: "8",
                    }
                });
                if(res.data.status != 200){
                    throw res.data.message;
                }
                this.destinations = res.data.data.item;
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        },
        async getPackages() {
            try {
                this.$store.commit('setOverlayLoading', true);
                const response = await this.$http.post(`${this.$apiTripweWeb}/package/list/all`, {
                    "id_wilayah": this.selectedDestination.fk_id_wilayah,
                    "nama_wilayah": this.selectedDestination.nama_wilayah,
                });
                if(response.data.status != 200){
                    throw response.data.message;
                }
                this.packages = response.data.data.item ?? [];

            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit('setOverlayLoading', false);
            }

        },
        selectDestination(destination) {
            this.selectedDestination = destination;

            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Destination', routeName: 'DestinationIndex' },
                { text: destination.judul, routeName: 'DestinationDetail' }
            ]);

            this.getPackages();
        },
        getLevelImage(level) {
            switch (parseInt(level)) {
                case 1:
                    return require('@/assets/icons/ic-level-beginner.svg');
                case 2:
                    return require('@/assets/icons/ic-level-intermediate.svg');
                default:
                    return require('@/assets/icons/ic-level-advance.svg');
            }
        },
        getLevelText(level) {
            switch (parseInt(level)) {
                case 1:
                    return 'Beginner';
                case 2:
                    return 'Intermediate';
                default:
                    return 'Advance';
            }
        }
    }
};
</script>
